<template>
  <div v-if="!isRouterReady" class="flex items-center justify-center h-[100dvh]">
    <div class="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
  </div>
  <template v-else>
    <header
      class="flex justify-between items-center px-4 py-3 border-b sticky top-0 bg-white"
      :class="{
        'hidden flex': !showHeaderOnMobile,
        flex: showHeaderOnMobile
      }"
    >
      <div class="flex gap-6 text-2xl">
        <RouterLink to="/" class="flex items-center gap-2 text-custom-alpineRed font-black">
          <div
            class="bg-custom-alpineRed rounded-full w-5 h-5 flex items-center"
            style="margin-top: -3px"
          >
            <img src="/img/logo.png" class="w-auto h-3 mx-auto" style="margin-top: -2px" />
          </div>
          Listener
        </RouterLink>
      </div>
      <div v-if="!isAuthPage" class="flex items-center space-x-2">
        <!--<RouterLink
          v-if="isLoggedIn"
          to="/library"
          class="p-2 rounded-full hover:bg-gray-200 transition-colors duration-200 md:hidden"
        >
          <BookOpen class="w-6 h-6 text-gray-600" />
        </RouterLink>-->
        <RouterLink
          to="/signin"
          class="px-4 py-1 md:py-2 rounded-full bg-custom-buttonPrimary text-white hover:bg-custom-buttonPrimaryHover transition-colors duration-200"
        >
          {{ $t('signin.btn_signinwithkeycloak') }}
        </RouterLink>
      </div>
    </header>
    <RouterView v-slot="{ Component }">
      <Suspense>
        <component :is="Component" />
        <template #fallback>
          <div class="flex items-center justify-center h-[100dvh]">
            <div
              class="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"
            ></div>
          </div>
        </template>
      </Suspense>
    </RouterView>
    <PWAPrompt />
  </template>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useRoute, useRouter, RouterLink } from 'vue-router'
import { BookOpen } from 'lucide-vue-next'
import { useUserStore } from '@/stores/userStore'
import PWAPrompt from '@/components/PWAPrompt.vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const route = ref(null)
const router = ref(null)
const isRouterReady = ref(false)
const userStore = ref(null)

// Computed properties that depend on route
const isHomePage = computed(() => route.value?.path === '/')
const isAuthPage = computed(() =>
  route.value ? ['/signin', '/register', '/forgot-password'].includes(route.value.path) : false
)
const showHeaderOnMobile = computed(() => isHomePage.value || isAuthPage.value)
const isLoggedIn = computed(() => userStore.value?.isLoggedIn || false)

// Initialize everything in onMounted
onMounted(async () => {
  try {
    // Initialize router and route
    router.value = useRouter()
    route.value = useRoute()

    // Wait for router to be ready
    await router.value.isReady()
    isRouterReady.value = true

    // Initialize store
    userStore.value = useUserStore()
  } catch (error) {
    console.error('Failed to initialize app:', error)
  }
})
</script>
