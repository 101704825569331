import { defineStore } from 'pinia'
import { keycloak, isAuthenticated } from '@/plugins/keycloak'
import axios from 'axios'

export const useUserStore = defineStore('user', {
  state: () => ({
    user: null,
  }),
  getters: {
    isLoggedIn: () => isAuthenticated(),
  },
  actions: {
    async fetchUserInfo() {
      if (isAuthenticated()) {
        try {
          const userProfile = await keycloak.loadUserProfile();
          const userRead = await axios.get('/api/auth/me');
          this.user = {
            id: userProfile.id,
            username: userProfile.username,
            email: userProfile.email,
            firstName: userProfile.firstName,
            lastName: userProfile.lastName,
            is_verified: userRead.data.is_verified,
            is_superuser: userRead.data.is_superuser
          }
        } catch (error) {
          console.error('Failed to fetch user info', error);
          this.user = null;
        }
      } else {
        this.user = null;
      }
    },
    async login() {
      await keycloak.login();
    },
    async logout() {
      await keycloak.logout();
      this.user = null;
    },
    async checkAuth() {
      if (isAuthenticated()) {
        try {
          await keycloak.updateToken(30);
          if (!this.user) {
            await this.fetchUserInfo();
          }
        } catch (error) {
          console.error('Failed to refresh token', error);
          this.user = null;
        }
      } else {
        this.user = null;
      }
    },
  },
})